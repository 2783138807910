.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.SigninSignup {
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.SigninSignup__heading{
  text-decoration-line: underline;
  height: 20%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.SigninSignup__container{
  height: 80%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}
@import url('https://fonts.googleapis.com/css2?family=Kanit:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Mono&display=swap');

.goback {
  position: absolute;
  top: 10px;
  right: 20px;
}

.about__outer {
  justify-content: center;
  align-items: center;
  margin-bottom: 50px;
}

.about__inner{
  height: 100%;
}

@media screen and (max-width: 1000px) {
  .about__outer {
    width: 100%;
  }

  .about__inner {
    height: auto;
    width: 100%;
    margin: 0 2%;
  }

  .about__inner1 {
    justify-content: center;
    align-items: center;
    height: auto;
    width: 100%;
    margin-right: -4%;
  }
  
  .about__inner2 {
    justify-content: center;
    align-items: center;
    
    margin: 0 2%;
    text-align: justify;
  }
}

.about__inner1 {
  justify-content: flex-end;
  align-items: center;
  height: 100%;
}

.about__inner2 {
  justify-content: center;
  align-items: center;
  height: 100%;
  margin-right: 6%;
  padding: 1% 2%;
  text-align: justify;
}

.Register {
  height: 100vh;
  width: 100vw;
  background: url("./assets/bg.png");
  background-size: 100% 100%;
  position: relative;
  overflow: hidden;
}

.Register__insta {
  position: absolute;
  height: 50px;
  width: 50px;
  bottom: 10%;
  left: 5%;
}

.Register__shape {
  position: absolute;
  width: 400px;
  height: 516px;
  bottom: 0;
  left: 12%;
}
.Register__form {
  position: absolute;
  bottom: 70px;
  left: 15%;
  height: 300px;
}

.Register__heading {
  position: absolute;
  right: 15%;
  top: 7%;
}

.Register__info {
  position: absolute;
  font-size: medium;
  width: 500px;
  right: 10%;
  top: 20%;
}

.Register__button {
  position: absolute;
  bottom: 5%;
  right: 10%;
  display: flex;
  flex-direction: column;
  text-align: center;
}

.Register__backarrow {
  position: absolute;
  top: 5%;
  left: 2%;
}

.voting {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.voting__heading {
  font-weight: 600;
  font-size: larger;
}

.voting__card {
  margin-top: 20px;
}

.votingcard__button {
  margin-top: 10px;
}

.voting__outer {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-around;
  align-items: center;
  margin-top: 30px;
}

@media screen and (max-width: 900px) {
  .Register {
    display: none;
  }
}

@media screen and (max-width: 1200px) {
  .Register__heading {
    right: 7%;
    top: 5%;
  }
  
  .Register__info {
    width: 350px;
    right: 4%;
    top: 20%;
  }
}