.navbar {
    font-family: 'Outfit', sans-serif;
    
    /* background-color: rgba(247, 240, 212, 0.7); */
}

/* .navbarlist li{
    background-color: rgba(247, 240, 212, 1);
} */

.navmobile {
    background: rgba(250, 239, 249, 0.432);
}