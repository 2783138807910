@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@300;400;500;700&display=swap');

@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Mono:wght@400;500;600;700&display=swap');

html {
	scroll-behavior:smooth
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;	
  background-color: #F7F0D4;
}

*{
  cursor: url('./assets/logo.png') 2 2, auto;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.mouseHover:hover {
  transform: scale(1.2);
}

.mouseOver {
  -webkit-filter: sepia(100%);
	filter: sepia(100%);
	-webkit-transition: .3s ease-in-out;
	transition: .3s ease-in-out;
}

.mouseOver:hover {
  -webkit-filter: sepia(0);
	filter: sepia(0);
}

.revealUp {
  opacity: 0;
  visibility: hidden;
}

@media screen and (max-width: 640px) {
	.hero {
		height: 89vh;
	}
}

/* @import url('https://fonts.googleapis.com/css?family=Paytone+One');
@import 'compass/css3';

body,html {
	background:#060a19;
	width:100%;
	height: 100%;
	overflow:hidden;
	font-family: Paytone One;
}

@mixin keyframes($animationName) {
  @-webkit-keyframes #{"$animationName"} {
    @content;
  }
  @-moz-keyframes #{$animationName} {
    @content;
  }

  @-ms-keyframes #{$animationName} {
    @content;
  }

  @keyframes #{$animationName} {
    @content;
  }
}
p {
	color: #fff;
	background: #000;
	mix-blend-mode: lighten;
	font-size:30px;
	text-align: center;
	position: absolute;
	left:0;
	right: 0;
	top:0;
	bottom: 0;
	margin:auto;
	height: 100px;
	@include animation(filterHue2 2s linear infinite);
	
	&::before {
		content: '';
		display: block;
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		background: #e23b4a;
		@include background(linear-gradient(to right,#23966c, #faaa54, #e23b4a, #db0768, #360670));
		pointer-events: none;
		mix-blend-mode: multiply;
	}
}

.loader-container {
	width:100px;
	height:100px;
	position: absolute;
	@include animation(scaleUp .5s linear);
	@include transform(scale(0));
	opacity: 0;
	.loader {
		background: #ff0c0c;
		@include background(linear-gradient(50deg,#ff0c0c,#21d400));
		border-radius:10px;
		@include filter(hue-rotate(0deg));
		animation: filterHue 2.5s linear infinite;
		height:100%;
		width: 100%;
		transform:rotate(0deg);
		&::after {
			content:'';
			position:absolute;
			height: 80px;
			width:80px;
			left:10px;
			top:10px;
			border-radius: 100%;
		}
	}
}

@include keyframes(filterHue2) {
	50%{@include filter(hue-rotate(1000deg));}
	100% {@include filter(hue-rotate(2000deg)); }
}

@include keyframes(filterHue) {
	50%{@include filter(hue-rotate(1000deg));}
	100% {@include filter(hue-rotate(2000deg));
	@include transform(rotate(360deg));}
}
@include keyframes(scaleUp) {
	50%{@include transform(scale(0.5));opacity: 1}
	100% {@include transform(scale(0.25));}
}


@supports (-ms-ime-align: auto) {
  p {
    color: #21d400;
    background: transparent;
    mix-blend-mode: lighten;
    font-size: 30px;
    text-align: center;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    height: 100px;
    @include animation(filterHue2 2s linear infinite);
  }
  p::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
  }
}


@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
	p {
		color: #ff447c;
		background: transparent;
		mix-blend-mode: lighten;
		font-size: 30px;
		text-align: center;
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		margin: auto;
		height: 100px;
		@include animation(filterHue2 2s linear infinite);
	}
	p::before {
		content: '';
		display: block;
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		background: transparent;
	}
	
	.loader {
		@include background(linear-gradient(50deg,rgba(240, 0, 80, 1),#2380ff) !important); 
	}
} */