.card{
    position: relative;
    width: 341px;
    height: 620px;
    border-radius: 27.9938px;}

.card-photo{
    position: absolute;
    width: 313.25px;
    height: 345.16px;
    left: calc(50% - 313.25px/2 + 0.84px);
    top: 13.44px;
    border-radius: 27.9938px;

}
.hover-class{
    border-radius: 27.9938px;
    

}

.line1{
    position: absolute;
    width: 223.4px;
    height: 0px;
    left: calc(50% - 223.4px/2 - 0.98px);
    top: 391.63px;

    border: 1.39969px solid #000000;
}

.year{
    position: absolute;
    width: 41px;
    height: 22px;
    left: 288.34px;
    top: 379.32px;

    font-family: 'IBM Plex Mono';
    font-style: normal;
    font-weight: 400;
    font-size: 16.7963px;
    line-height: 22px;
    /* identical to box height */


    color: #000000;

}

.name{
    position: absolute;
    width: 284px;
    height: 36px;
    left: 13.72px;
    top: 442.9px;

    font-family: 'IBM Plex Mono';
    font-style: normal;
    font-weight: 500;
    font-size: 25px;
    line-height: 36px;

    color: #000000;

}

.position{
    position: absolute;
    width: 223px;
    height: 28px;
    left: 15.96px;
    top: 482.05px;

    font-family: 'IBM Plex Mono';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 28px;

    color: #000000;



    color: #000000;
}

.line2{
    position: absolute;
    width: 223.4px;
    height: 0px;
    left: calc(50% - 223.4px/2 - 58.8px);
    top: 537px;

    border: 1.39969px solid #000000;

}

.logo{
    position: absolute;
    width: 114.28px;
    height: 35.47px;
    left: 12.72px;
    top: 569px;
}

.barcode{
    position: absolute;
    width: 108px;
    height: 40px;
    left: 213px;
    top: 570px;
}

.stamp{
    position: absolute;
    width: 79.22px;
    height: 83.42px;
    left: 221.15px;
    top: 251.66px;
}

.xtasy{
    position: absolute;
    width: 41.43px;
    height: 45.07px;
    left: 11.2px;
    top: 363.36px;


}
